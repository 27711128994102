<template>
  <v-col class="px-md-16 pt-16 center blue-bg pp-relative">
    <v-img
        src="/images/shades/splash-bg-1.jpg"
        alt="LearningPlus Trainings"
        cover
        class="hidden-sm-and-up"
    />

    <div class="splash-left hidden-sm-and-down"></div>

    <div class="pl-md-10 pt-md-2 pt-6">
      <h1 class="responsive-screen primary--text text-lg-h1 font-weight-bold text-h3 text-center">
        Learning<span class="grey--text">Plus</span>

        <p class="grey--text text-h4 font-weight-medium text-right pr-xl-16 mr-xl-16">
          by PeoplePlus
        </p>
      </h1>

      <v-card-text class="text-lg-h4 text-h5 font-weight-medium primary--text text-center font-weight-bold">
        Unlock the Power of Learning
      </v-card-text>

      <h2 class="pp-subtitle-h2 mt-2 px-xl-16 mt-6 mt-lg-12 hidden-xs-only">
        Part of PeoplePlus, one of the UK’s leading employability and training providers, LearningPlus is our online learning solution. We help individuals develop their careers and assist organisations meet regulatory requirements for the delivery, certification, and management of training.
      </h2>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "Splash1",
};
</script>

<style scoped>
@media screen and (max-width: 1370px) and (min-width: 1270px) {
  .responsive-screen {
    font-size: 5.5rem !important;
  }
  .pp-subtitle-h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1270px) and (min-width: 1240px) {
  .responsive-screen {
    font-size: 4rem !important;
  }
}
</style>
