<template>
  <v-container fluid class="ma-0 pa-0 pp-full-width">
    <v-row>
      <v-col xs="12" sm="6" md="6" lg="6" xl="6"
             class="px-0"
      >
        <Splash1></Splash1>
      </v-col>

      <v-col sm="6" md="6" lg="6" xl="6"
             class="pa-0"
      >
        <Splash2></Splash2>
      </v-col>
    </v-row>
    <SearchDetailed />
    <div class="px-xl-16">
      <Catalogue class="px-xl-16" />
    </div>
    <v-lazy v-model="isActive"
            :options="{ threshold: .5 }"
            transition="fade-transition"
    >
      <Splash3Mobile />
    </v-lazy>
    <v-lazy v-model="isActive"
            :options="{ threshold: .5 }"
            transition="fade-transition"
    >
      <Splash3 />
    </v-lazy>
  </v-container>
</template>

<script>
import Splash1 from "@/components/splash/Splash1";
import Splash2 from "@/components/splash/Splash2";
import Splash3 from "@/components/splash/Splash3";
import Splash3Mobile from "@/components/splash/Splash3Mobile";
import SearchDetailed from "@/components/search/SearchDetailed";
import Catalogue from "@/components/Catalogue";


export default {
  name: "Splash",
  components: {Catalogue, SearchDetailed, Splash3Mobile, Splash1, Splash2, Splash3},
  data: () => ({
    isActive: false,
  }),
};
</script>
