<template>
  <v-card
      width="100%"
      class="rounded-xl pa-10">
    <div class="text-h4 font-weight-bold secondary--text mb-5 text-center">
      <v-icon large color="primary">
        mdi-magnify
      </v-icon>
      Sorry, no results were found.
    </div>

    <v-card class="rounded-xl pa-10 text-center grey lighten-3" flat>
      <div class="text-h6">
        Search Suggestions:
      </div>

      <ul>
        <li>Check your spelling.</li>
        <li>Try more general words.</li>
        <li>Try different words that mean the same thing.</li>
      </ul>
    </v-card>

    <div class="text-center">
      <div class="text-h6 mt-5">
        Any Inquiries?
      </div>

      <div class="mb-5">
        Contact us and we will help you find the course you are looking for.
      </div>

      <ContactUsButton />
    </div>
  </v-card>
</template>

<script>
import ContactUsButton from "@/components/buttons/ContactUsButton";
export default {
  name: "NoResult",
  components: {ContactUsButton},
};
</script>
