<template>
  <div class="mr-2">
    &pound;
    <span class="price-font">
        {{unitsToDecimal(course.priceInUnits)}}
      </span>
  </div>
</template>

<script>
import {unitsToDecimal} from "@/utility";
export default {
  name: "Price",
  props: ['course'],
  methods: {
    unitsToDecimal: unitsToDecimal,
  },
};
</script>

<style scoped>

.price-font {
  font-weight: bold;
  letter-spacing: 0.001rem;
  color: #4a4a4a;
  font-size: 18px;
}
</style>
