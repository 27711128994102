<template>
  <div>
    <div>
      <v-hover v-slot="{ hover }"
               class="ml-xl-6"
      >
        <v-card class="rounded-xl pp-ctg-card"
                :height="height"
                elevation="10"
                @click="navigateCategory(category);"
        >
          <picture v-if="category.image">
            <img
                 :src="category.image.url"
                 :alt="category.image.alt"
                 height="100%"
                 class="cover rounded-xl"
                 width="100%"
            />
          </picture>

          <picture v-else>
            <img
                class="pp-box rounded-xl cover"
                src="/images/categories/pp-default.jpg"
                alt="A LearningPlus Course"
            />
          </picture>

          <div class="pp-box pp-stack-top rounded-xl"></div>

          <div class="pp-box pp-card-content">
            <div
                class="text-lg-h4 text-h5 font-weight-bold mt-5 pa-3 white--text pp-left-line font-weight-medium"
            >
              {{category.name}}
            </div>
          </div>

          <v-expand-transition>
            <v-card
                v-if="hover"
                height="100%"
                class="transition-fast-in-fast-out v-card--reveal pp-dark rounded-xl pp-card-content hidden-sm-and-down"
            >
              <p class="pa-6 text-h7 white--text pb-0 pp-desc"
              >
                {{category.description}}
              </p>

              <div class="px-6">
                <v-btn
                    block
                    rounded
                    color="primary"
                    class="pp-scale-1 py-6"
                    title="View all courses"
                    aria-labelledby="View all courses"
                >
                  View all courses
                  <v-icon class="ml-2">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-expand-transition>
        </v-card>
      </v-hover>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import router from "@/router";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "CategoryCard",
  mixins: [matomoEcommerce],
  props: {
    category: Object,
    navigateToCategory: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    height () {

      let height = 336;

      if(this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
        height = 160;
      }

      return height;
    },
  },

  methods: {
    ...mapActions(['setCategorySelected']),
    navigateCategory(category) {
      this.matomoEvent('Navigate Page', 'Navigate Page Click', 'View Category');
      this.matomoSetEcommerceView(false, false, [category.name]);
      this.setCategorySelected({category: category, page: 1});
      router.push({path: '/category/' + category.slug});
    },
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style>
.pp-dark {
  background-color: #030303;
  background-image: linear-gradient(160deg, #030303 0%, #545454 100%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
