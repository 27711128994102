<template>
  <v-container fluid class="hidden-lg-and-up pa-0">
    <div class="primary--text text-h6 ml-3 mb-5">
      <v-icon color="primary" class="pr-2">
        mdi-trending-up
      </v-icon>
      Our most in-demand courses
    </div>

    <Loading title="Loading Popular Courses"
             :loading="popularCoursesLoading" />

    <hooper
        v-if="! popularCoursesLoading"
        :settings="hooperCatalogueMobileConfig"
        class="mobile-height"
    >

      <slide v-for="(course, index) in popularCourses" :key="index">

        <v-card class="rounded-xl elevation-5 mt-3" width="90%">
          <picture>
            <img
                v-if="course.image"
                :src="course.image.url"
                :alt="course.image.alt"
                height="120"
                @click="navigateCourse(course)"
                width="100%"
                class="cover rounded-top"
            />

          <img v-else
                 src="/images/categories/pp-default.jpg"
                 alt="LearningPlus Course"
                 height="120"
            />
          </picture>

          <v-card-text class="pp-relative">
            <v-row>
              <div>
                <v-chip
                        color="primary"
                        right
                        small
                        v-if="course.popular"
                        class="ml-2 mtn-15"
                >
                  <v-icon class="mr-1" small>
                    mdi-trending-up
                  </v-icon>
                  In Demand
                </v-chip>

                <v-chip v-else
                        small
                        color="transparent"
                        class="demand-chip"
                >
                </v-chip>
              </div>

              <v-btn
                  absolute
                  fab
                  right
                  top
                  small
                  outlined
                  color="white"
                  title="Add to basket"
                  aria-labelledby="Add to basket"
                  class="mtn-15"
              >
                <BuyNowCompact :item="course"/>
              </v-btn>
            </v-row>

            <v-card flat color="transparent"
                    class="pp-pointer"
                    @click="navigateCourse(course)"
                    :ripple="false"
            >
              <h3 class="font-weight-medium primary--text">
                {{ course.name }}
              </h3>

              <v-row class="ml-1 my-1">
                <div>
                  <div class="font-weight-bold secondary--text text-subtitle-2 mr-2"
                       v-if="course.level"
                  >
                    Level: {{ course.level.name }}
                  </div>
                </div>
                <v-spacer/>
                <Price
                    v-if="course.priceInUnits"
                    :course="course"
                />
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </slide>
    </hooper>

    <div class="pp-title mb-5">
      Browse Categories
    </div>

    <v-col md="12" lg="7">

      <Loading title="Loading Categories" :loading="categoriesLoading" />

      <v-row v-if="!categoriesLoading"
             class="pp-full-width"
      >
        <v-col cols="12" sm="4"
               v-for="(category, index) in categories"
               :key="index">

          <CategoryCard :category="category"/>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Hooper, Slide} from 'hooper';
import 'hooper/dist/hooper.css';
import router from "@/router";
import BuyNowCompact from "@/components/basket/BuyNowCompact";
import Loading from "@/components/common/Loading";
import CategoryCard from "@/components/CategoryCard";
import Price from "@/components/Price";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "CatalogueMobile",
  props: ['course', 'compact', 'navigateToCourse'],
  mixins: [matomoEcommerce],
  components: {
    Price,
    CategoryCard,
    Loading,
    BuyNowCompact,
    Hooper,
    Slide,
  },

  computed: {
    ...mapGetters(['popularCourses', 'popularCoursesLoading', 'categories', 'categoriesLoading']),

    hooperCatalogueMobileConfig() {
      let config = {
        infiniteScroll: true,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: true,
        transition: 500,
        hoverPause: false,
        centerMode: false,
      };

      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          config.itemsToShow = 1.2;
          break;
        case 'sm':
          config.itemsToShow = 2.5;
          break;
        case 'md':
          config.itemsToShow = 3.5;
          break;
      }

      return config;
    },

  },

  methods: {
    ...mapActions(['setCourseSelected', 'getCategories']),
    navigateCourse(course) {
      this.matomoEvent('View Course', 'View Course Click', 'View Course');
      this.matomoSetEcommerceView(course._id, course.name, "", course.priceInUnits);
      this.setCourseSelected(course);
      router.push({path: '/course/' + course.slug});
    },

    // @todo I don't think this is being used
    loadCategories: function (page) {
      this.getCategories({inputOptions: {page: page}});
    },
  },
};
</script>

<style>
.mobile-height {
  min-height: 330px;
}

.mtn-15 {
  top: -30px !important;
}

.rounded-top {
  border-radius: 25px 25px 0 0;
}
</style>
