<template>
  <v-container fluid class="px-md-16">
    <v-container  fluid class="px-xl-16 pa-0 ma-0">
      <v-row class="search-box">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search for the course, category or provider you're looking for"
            single-line
            hide-details
            flat
            height="60"
            filled
            color="primary"
            solo
            rounded
            placeholder="Start typing to search course, category or provider you're looking for"
            class="mx-xl-16 text-field"
        ></v-text-field>
      </v-row>
      <Loading
          :loading="searchResultsLoading"
          title="Loading Search Results"
          class="my-10 accent--text"
      />
    </v-container>

    <SearchResultList/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchResultList from "@/components/search/SearchResultList";
import Loading from "@/components/common/Loading";

export default {
  name: "SearchDetailed",
  components: {Loading, SearchResultList},
  data() {
    return {
      selectedCategory: null,
      items: [],
      search: '',
    };
  },
  computed: {
    ...mapGetters(['searchResultsLoading', 'courses']),
  },
  methods: {
    ...mapActions(['searchCourses', 'setCourseSelected', 'hideSearchResults']),
  },
  watch: {
    search: {
      handler(val) {
        this.searchCourses({search: val});
      },
    },
},
};
</script>

<style scoped>

.search-box {
  margin-left: 20px;
  width: 40vw;
  transition: width 0.5s;
}

.search-box:hover {
  width: 85vw !important;
}

.text-field {
  display: flex;
  box-shadow: 0 0 .5em .04em rgba(46,47,48,.3),0 .25em 1em rgba(90,125,188,.05);
  cursor: pointer;
  position: relative;
  border-radius: 40px;
  z-index: 10 !important;
  top: -48px;
  width: 20vw !important;
}

.text-field:hover {
  background-color: white !important;
  border: var(--primary) solid 2px;
}

@media screen and (max-width: 600px){
  .search-box {
    margin: 50px 0 0 0;
    width: 90vw !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 810px) {
  .search-box {
    margin-left: 15px !important;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1081px) {
  .search-box {
   margin-left: 30px !important;
  }
}

@media screen and (max-width: 1900px) and (min-width: 1367px) {
  .search-box {
    margin-left: 30px !important;
  }
}


</style>
