<template>
  <v-container
      v-if="visibleSearchResults"
      class="px-xl-16 mb-15"
      fluid
  >
    <template v-if="searchResults.length >= 1">
      <v-container fluid class="pa-0">
        <v-expansion-panels
            v-model="panel"
            multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Result(s):
              <template v-slot:actions>
                <v-icon large color="primary">
                  mdi-chevron-down-box
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="px-xl-16">
                <v-col cols="12" sm="5" md="4" lg="3" xl="3"
                       v-for="course in searchResults"
                       :key="course._id"
                       class="px-xl-2"
                >
                  <CourseCompact :course="course" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="my-4" />
      </v-container>
    </template>


    <v-row v-if="searchResults.length === 0">
      <NoResult />
    </v-row>

    <Pagination :options="searchResultsPagination" :callback="loadSearch" />
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Pagination from "@/components/common/Pagination";
import NoResult from "@/components/search/NoResult";
import CourseCompact from "@/components/course/CourseCompact";

export default {
  name: "SearchResultList",
  components: {CourseCompact, NoResult, Pagination},
  computed: {
    ...mapState(['searchResultsPagination', 'searchValue']),
    ...mapGetters(['courses', 'visibleSearchResults', 'searchResults', 'searchResultsPagination', 'searchValue']),
  },
  data: () => ({
    panel: [0],
  }),
  methods: {
    ...mapActions(['searchCourses', 'clearSearchResults']),
    loadSearch: function(page){
      this.searchCourses({search: this.searchValue, inputOptions: {page: page}});
    },
  },
  beforeDestroy() {
    this.clearSearchResults();
  },
};
</script>

<style>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
