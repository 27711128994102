<template>
  <v-btn fab
         small
         elevation="5"
         title="Add to Basket"
         aria-labelledby="Add to Basket"
         @click="add"
  >
    <v-icon color="primary">
      mdi-cart-outline
    </v-icon>
  </v-btn>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "BuyNowCompact",
  mixins: [matomoEcommerce],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
      ...mapGetters(['basketValue']),
  },
  methods: {
    ...mapActions(['addToBasketItem', 'showBasket']),
    add: function () {
      this.matomoEvent('Basket', 'Basket Click', 'Add to basket Shortcut');
      this.item.amountToAddToBasket = 1;
      this.addToBasketItem(this.item);
      this.matomoAddBasket(this.item, this.basketValue);
      this.showBasket();
    },
  },
};
</script>
