<template>
  <v-container class="primary ma-0 pa-sm-15 mt-15 pt-8" fluid>
    <v-container>
      <v-row class="pp-title justify-center white--text">
        Start your learning journey here
      </v-row>

      <v-row
          align="center"
          justify="center"
          class="mt-15"
      >
        <template v-for="(customer, i) in customers">
          <v-col
              :key="i"
              cols="12"
              lg="4"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  class="rounded-xl pp-scale-1 ma-md-2"
                  @click="navigateTo($event, customer.path, 'CardNavigation');"
              >
                <picture>
                  <img :src="customer.img"
                       alt="LearningPlus Customer"
                       height="100%" width="100%" />
                </picture>

                <div class="ma-7">
                  <picture>
                    <img :src="customer.logo"
                         alt="LearningPlus Logo"
                         class="cust-logo"/>
                  </picture>


                  <div class="pp-customer-title">
                    {{ customer.title }}
                  </div>

                  <v-card
                      height="120"
                      flat
                      class="text-sm-body-1 text-lg-subtitle-2 text-xl-h6"
                  >
                    {{ customer.text }}
                  </v-card>

                  <v-card
                      flat
                      height="70"
                      class="text-center mt-5 mt-md-0"
                  >
                    <v-btn
                        class="lp-btn primary text-md-h6 text-sm-subtitle-1 text-subtitle-2"
                        @click="navigateTo($event, customer.path, 'ButtonNavigation');"
                    >
                      {{ customer.button }}
                    </v-btn>
                  </v-card>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import router from "@/router";

export default {
  name: "CustomerTypes",
  data: () => ({
    customers: [
      {
        title: 'Learner',
        text: `Do you want to gain accredited online qualifications in the comfort of your own home? With over 400 courses to choose from there is something for everyone.`,
        img: '/images/customer/learner.jpg',
        logo: '/images/logo/lp-individual.svg',
        button: 'Discover all courses',
        path: "/learners",
      },
      {
        title: 'Employer',
        text: 'Are you an employer searching for a digital solution to meet your training needs?',
        img: '/images/customer/employer.jpg',
        logo: '/images/logo/pp-logo-business.svg',
        button: 'Explore training solutions',
        path: "/employers",
      },
      {
        title: 'Education Provider',
        text: 'Do you work in the education sector and need a blend of accredited and non accredited online training?',
        img: '/images/customer/education.jpg',
        logo: '/images/logo/lp-education.svg',
        button: 'Explore education solutions',
        path: "/education-sector",
      },
    ],
  }),

  methods: {
    navigateTo(event, path, whichButton) {
      event.preventDefault();
      event.stopPropagation();

      switch (whichButton) {
        case 'CardNavigation':
          this.matomoEvent('Page Navigation', 'Navigate to page Click', 'Navigate via Card', whichButton);
          break;
        case 'ButtonNavigation':
          this.matomoEvent('Page Navigation', 'Navigate to page Click', 'Navigate via Button', whichButton);
          break;
      }

      router.push({ path: path }).catch(err => {

        if(err.name === 'NavigationDuplicated'){
          window.location.href = path;
        }
      });
      return false;
    },
  },
};
</script>

<style scoped>

.pp-customer-title {
  font-size: 30px;
  font-weight: bold;
  color: var(--primary);
}

.lp-btn {
  border-radius: 50px;
  padding: 25px !important;
}

.lp-btn:hover {
  transform: scale(1.08);
}

.text-subtitle-2 {
  line-height: 20px;
}

.cust-logo {
  max-width: 190px;
  object-fit: contain;
}
</style>
