<template>
  <v-hover v-slot="{ hover }">
    <v-card
        class="mx-auto rounded-xl mb-xl-5 mb-5 elevation-5"
        color="grey lighten-4"
        max-width="420"
    >
      <v-img
          v-if="course.image"
          :src="course.image.url"
          :alt="course.image.alt"
          :aspect-ratio="16/9"
          height="140"
          @click="navigateCourse(course);"
          class="pp-pointer pp-item"
      >
        <v-expand-transition>
          <div
              v-if="hover"
              class="d-flex v-card--reveal text-h6 white--text"
          >
            Learn More
            <v-icon class="ml-2 white--text">
              mdi-chevron-right
            </v-icon>
          </div>
        </v-expand-transition>
      </v-img>

      <v-img
          v-else
          src="/images/categories/pp-default.jpg"
          alt="LearningPlus Course"
          aspect-ratio="16/9"
          height="140"
          @click="navigateCourse(course);"
          class="pp-pointer pp-item"
      >
        <v-expand-transition>
          <div
              v-if="hover"
              class="d-flex v-card--reveal text-h6 white--text"
          >
            Learn More
            <v-icon class="ml-2 white--text">
              mdi-chevron-right
            </v-icon>
          </div>
        </v-expand-transition>
      </v-img>

      <v-card-text class="pp-relative">
        <v-row>
          <v-chip class="pp-popular pp-absolute demand-chip"
                  color="primary"
                  right
                  small
                  v-if="course.popular"
          >
            <v-icon class="mr-1" small>
              mdi-trending-up
            </v-icon>
            In Demand
          </v-chip>

          <v-chip v-else
                  small
                  color="transparent"
                  class="demand-chip"
          >
          </v-chip>

          <v-btn
              absolute
              fab
              right
              top
              small
              outlined
              color="white"
          >
            <BuyNowCompact :item="course"/>

          </v-btn>
        </v-row>

        <v-card flat color="transparent"
                class="pp-pointer"
                @click="navigateCourse(course);"
                :ripple="false"
               >
          <h1 class="text-h6 font-weight-bold primary--text pr-7">
            {{ course.name }}
          </h1>

          <v-row class="ml-1 mt-2 mb-1">
            <div>
              <div class="font-weight-bold secondary--text text-subtitle-2 mr-2"
                   v-if="course.level">
                Level: {{ course.level.name }}
              </div>
            </div>
            <v-spacer />
            <Price
                v-if="course.priceInUnits"
                :course="course"
            />
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import {mapActions} from "vuex";
import BuyNowCompact from "@/components/basket/BuyNowCompact";
import Price from "@/components/Price";
import router from "@/router";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "CourseCompact",
  mixins: [matomoEcommerce],
  components: {Price, BuyNowCompact },
  props: {
    course: {
      type: Object,
    },
    navigateToCourse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['setCourseSelected']),
    navigateCourse(course) {
      this.setCourseSelected(course);
      router.push({path: '/course/' + course.slug});
      this.matomoEvent('View Course', 'View Course Click', 'View Course');
      this.matomoSetEcommerceView(course._id, course.name, "", course.priceInUnits);
    },
    goBack(){
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.text-h6 {
  line-height: 22px;
  margin: 4px 0;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image:
      linear-gradient(
          180deg,
          rgb(146, 3, 0),
          rgba(165, 5, 0, .5),
          rgba(255, 255, 255, 0));
}

.demand-chip {
  top: -15px;
  left: 10px;
  z-index: 1;
}

</style>
