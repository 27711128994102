<template>
  <div v-if="options.totalPages >= 2" class="text-center">
    <v-pagination
        v-model="options.page"
        :length="options.totalPages"
        circle
        @input="input"
        @next="next"
        @previous="previous"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    options: {
      type: Object,
      default: function(){
        return {
          page: 0,
          totalPages: 0,
        };
      },
    },
    callback: {
      type: Function,
    },
  },
  methods: {
    input: function(page){
      this.callback(page);
      window.scrollTo(0, 300);
    },
    next: function(){
      if(this.options.page < this.options.totalPages){
        this.callback(this.options.page + 1);
        window.scrollTo(0, 300);
      }
    },
    previous: function(){
      if(this.options.page != 1){
        this.callback(this.options.page - 1);
        window.scrollTo(0, 300);
      }
    },
  },
};
</script>
