<template>
  <div class="hidden-md-and-up py-10">

    <v-card class="rounded-xl py-5">
      <div class="px-6">

        <v-card-text class="primary--text text-h5 font-weight-bold mb-2 text-center">
          We turn your visions into actions
        </v-card-text>

        <v-row>
          <v-col cols="1">
            <v-icon left  color="primary">
              mdi-checkbox-multiple-marked-circle-outline
            </v-icon>
          </v-col>

          <v-col class="ml-2">
            Fully compliant with the regulatory requirements
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <v-icon left  color="primary">
              mdi-clock-time-two-outline
            </v-icon>
          </v-col>

          <v-col class="ml-2">
            Plan and develop your career
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <v-icon left  color="primary">
              mdi-star-circle-outline
            </v-icon>
          </v-col>

          <v-col class="ml-2">
            Nationally recognised qualifications
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <v-icon left  color="primary">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-col>

          <v-col class="ml-2">
            Keep up in a fast paced job market
          </v-col>
        </v-row>
      </div>

      <hooper
          class="mt-5 hooper-height"
          :settings="hooperSplashMobileConfig">

        <slide>
          <v-card
              class="mt-3 pt-7 rounded-xl red-gradient-container pa-0"
              width="90%"
              min-height="160"
          >

            <v-card-text class="pp-subtitle accent--text text-h5 text-center">
              {{ marketingNumbers.coursesDeliveredMedium.toLocaleString() }}&#43;
            </v-card-text>

            <div class="pp-subtitle accent--text text-h7 text-center pb-4">
              Courses Delivered
            </div>
          </v-card>
        </slide>

        <slide>
          <v-card
              class="mt-3 rounded-xl red-gradient-container pa-0"
              width="90%"
          >
            <v-card-text class="pp-subtitle accent--text text-h3 text-center">
              {{ marketingNumbers.coursesAvailable }}&#43;
            </v-card-text>
            <div class="pp-subtitle accent--text text-h6 text-center pb-4">
              Courses to <br />choose from
            </div>
          </v-card>
        </slide>

        <slide>
          <v-card
              class="mt-3 rounded-xl red-gradient-container pa-0"
              width="90%"
          >
            <v-card-text class="pp-subtitle accent--text text-h3 text-center">
              {{ marketingNumbers.employerSites }}&#43;
            </v-card-text>
            <v-card-text class="pp-subtitle accent--text text-h6 text-center">
              Employer Sites Supported
            </v-card-text>
          </v-card>
        </slide>

        <slide>
          <v-card
              class="mt-3 rounded-xl red-gradient-container pa-0"
              width="90%"
          >
            <v-card-text class="pp-subtitle accent--text text-h3 text-center pb-0">
              {{ marketingNumbers.yearsInBusiness }}&#43;
            </v-card-text>

            <v-card-text class="pp-subtitle accent--text text-h6 text-center pb-9">
              Years in Business
            </v-card-text>
          </v-card>
        </slide>

        <hooper-navigation slot="hooper-addons"></hooper-navigation>

      </hooper>

      <v-btn rounded
             class="ml-4 mt-3 px-8 pp-scale"
             x-large
             width="90%"
             outlined
             color="primary"
             to="/about"
             @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'About Us Page');"
             title="Learn more"
             aria-labelledby="Learn more about LearningPlus"
      >
        Learn more about us
        <v-icon class="pl-4">
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import router from "@/router";

export default {
  name: "Splash3Mobile",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },

  computed: {
    ...mapState(['marketingNumbers']),
    ...mapGetters(['marketingNumbers']),

    hooperSplashMobileConfig() {
      let config = {
        itemsToShow: 1.25,
        infiniteScroll: true,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
        transition: 500,
        hoverPause: false,
        centerMode: false,
      };

      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          config.itemsToShow = 1.5;
          break;
        case 'sm':
          config.itemsToShow = 2.5;
          break;
      }

      return config;
    },

  },

  methods: {
    ...mapActions(['setCourseSelected']),
    navigateCourse(course) {
      this.matomoEvent('View Course', 'View Course Click', 'View Course');
      this.setCourseSelected(course);
      router.push({path: '/course/' + course.slug});
    },
  },
};
</script>

<style>
.hooper-height {
  height: 220px
}
</style>
