<template>
  <div>
    <title>LearningPlus Home Page</title>
    <BusinessLanding v-if="hasRole($auth.user, ['LearningPlusBusiness'])" />

    <v-container v-else fluid>
      <Splash></Splash>

      <v-lazy v-model="isActive"
              :options="{ threshold: .5 }"
              transition="fade-transition"
      >
        <CustomerTypes id="customer" />
      </v-lazy>
    </v-container>
  </div>
</template>

<script>
import Splash from "@/components/splash/Splash";
import CustomerTypes from "@/components/CustomerTypes";
import BusinessLanding from "@/views/BusinessLanding";
import { hasRoleMixin } from "@/mixin/hasRoleMixin";

export default {
  name: 'Home',
  mixins: [hasRoleMixin],
  components: {BusinessLanding, CustomerTypes, Splash},
  data: () => ({
    isActive: false,
  }),
};
</script>
