<template>
  <div>
    <v-container class="accent lp-shade hidden-sm-and-down px-xl-16">

      <v-container>
        <div class="layer-4 mt-8 pt-8">
          <v-row>
            <v-col class="numbers" lg="7">
              <v-row>

                <v-col class="pr-15 mr-xl-16">
                  <v-card-text class="courses-delivered text-lg-h1 hidden-md-and-down">
                    {{ marketingNumbers.coursesDelivered }}k&#43;
                  </v-card-text>

                  <v-card-text class="hidden-lg-and-up pp-subtitle text-h3 text--secondary">
                    {{ marketingNumbers.coursesDeliveredMedium.toLocaleString() }}&#43;
                  </v-card-text>

                  <v-card-text class="pp-subtitle secondary--text text-lg-h4 text-h5">
                    Courses Delivered
                  </v-card-text>
                </v-col>

                <v-col class="pr-15">
                  <v-card-text class="pp-subtitle text-lg-h1 text-h3">
                    {{ marketingNumbers.coursesAvailable }}&#43;
                  </v-card-text>
                  <v-card-text class="pp-subtitle text-lg-h4 text-h5">
                    Courses to choose from
                  </v-card-text>
                </v-col>

                <v-col class="hidden-lg-and-down" lg="2"></v-col>

                <v-col class="pr-15 mr-xl-16">
                  <v-card-text class="pp-subtitle text-lg-h1 text-h3">
                    {{ marketingNumbers.employerSites }}&#43;
                  </v-card-text>
                  <v-card-text class="pp-subtitle text-lg-h4 text-h5">
                    Employer Sites Supported
                  </v-card-text>
                </v-col>

                <v-col class="mr-xl-16">
                  <v-card-text class="pp-subtitle secondary--text text-lg-h1 text-h3">
                    {{ marketingNumbers.yearsInBusiness }}&#43;
                  </v-card-text>
                  <v-card-text class="pp-subtitle secondary--text text-lg-h4 text-h5">
                    Years in Business
                  </v-card-text>
                </v-col>

                <v-col class="hidden-lg-and-down" cols="2"> </v-col>
              </v-row>
            </v-col>

            <v-col>
              <div>
                <v-card class="card-info" elevation="5">
                  <v-icon left large color="primary">
                    mdi-checkbox-multiple-marked-circle-outline
                  </v-icon>
                  Fully compliant with the regulatory requirements
                </v-card>

                <v-card class="card-info" elevation="5">
                  <v-icon left large color="primary">
                    mdi-clock-time-two-outline
                  </v-icon>
                  Plan and develop your career
                </v-card>

                <v-card class="card-info" elevation="5">
                  <v-icon left large color="primary">
                    mdi-star-circle-outline
                  </v-icon>
                  Nationally recognised qualifications
                </v-card>

                <v-card class="card-info" elevation="5">
                  <v-icon left large color="primary">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                  Keep up in a fast paced job market
                </v-card>

                <v-btn rounded
                       class="ml-4 mt-3 px-8 pp-scale"
                       x-large
                       outlined
                       color="primary"
                       to="/about"
                       @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'About Us Page');"
                       title="About us"
                       aria-labelledby="Learn More About LearningPlus"
                >
                  About us
                  <v-icon class="pl-4">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-container>

  </div>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "Splash3",
  computed: {
    ...mapState(['marketingNumbers']),
    ...mapGetters(['marketingNumbers']),
  },
};
</script>

<style scoped>
.courses-delivered {
  color: #4c4c4c;
  font-size: 5.4rem !important;
  font-weight: bold;
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .courses-delivered {
    font-size: 3rem !important;
  }
}
</style>
