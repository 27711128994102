<template>
  <div class="mx-xl-16">
    <div class="pp-title hidden-xs-only">
      Browse Categories
    </div>

    <CatalogueMobile />

    <v-container class="hidden-md-and-down" fluid>
      <v-row class="px-xl-16">
        <v-col class="d-flex" cols="12" sm="3">
          <v-card class="transparent" flat>
            <v-card-title class="font-weight-bold px-0">
              Our most in-demand courses
            </v-card-title>
            <v-card-text class="font-weight-bold text-body-1 px-0">
              Our current popular online courses that are competitively priced to benefit your business or career.
            </v-card-text>

            <Loading title="Loading Popular Courses"
                     :loading="popularCoursesLoading"
            ></Loading>

            <div v-if="! popularCoursesLoading">
              <div v-for="(course, index) in popularCourses"
                   :key="index"
                   class="hidden-xs-only"
              >
                <div @click="navigatePopularCourse()">
                  <CourseCompact :course="course" :navigate-to-course="true"/>
                </div>

              </div>
            </div>
          </v-card>
        </v-col>

        <v-divider vertical />

        <v-col class="d-flex" cols="12" sm="9">
          <Loading title="Loading Categories"
                   :loading="categoriesLoading"
                   class="pp-full-width">
          </Loading>

          <v-card class="transparent" flat>

            <v-row v-if="!categoriesLoading"
                   class="pp-full-width"
            >
              <v-col cols="12" sm="3" md="4"
                     v-for="(category, index) in categories"
                     :key="index">
                <CategoryCard :category="category" />
              </v-col>
            </v-row>

            <v-row>
              <div class="mx-auto">
                <Pagination
                    v-if="!categoriesLoading"
                    :options="categoriesPagination"
                    :callback="loadCategories"
                />
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Loading from "@/components/common/Loading";
import CategoryCard from "@/components/CategoryCard";
import CatalogueMobile from "@/components/CatalogueMobile";
import Pagination from "@/components/common/Pagination";
import CourseCompact from "@/components/course/CourseCompact";

export default {
  name: "Catalogue",
  components: {CourseCompact, Pagination, CatalogueMobile, CategoryCard, Loading},
  computed: {
    ...mapState(['popularCourses', 'popularCoursesLoading', 'categories', 'categoriesLoading', 'categoriesPagination']),
    ...mapGetters(['popularCourses', 'popularCoursesLoading', 'categories', 'categoriesLoading', 'categoriesPagination']),
  },
  methods: {
    ...mapActions(['getCategories']),
    loadCategories: function(page) {
      this.getCategories({inputOptions: {page: page}});
    },
    navigatePopularCourse() {
      this.matomoEvent('Popular Course', 'Popular Course Click', 'Popular Course Shortcut');
    },
  },
};
</script>
